<template>
  <b-card  title="关联信息">
    <b-card-body>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-address-book"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">联系人数量</span>
            </th>
            <td class="pb-50">
              <b-badge  variant="light-primary" class="mr-50">
              {{ companyData.contacts_count }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-file-signature"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">合同状态</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge variant="light-success" v-if="contractStatus">
                有效
              </b-badge>
              <b-badge variant="light-danger" v-else>
                无效
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-handshake"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">合作状态</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{  }}
            </td>
          </tr>
        </table>
       </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
      contracts: [],
      contractStatus: false,
    }
  },
  created() {
  },
  props:{
    companyData:{
      type:Object,
      default:()=>{}
    },
    contractData:{
      type:Array,
      default:()=>[]
    },
  },

   methods:{
    computeRemainDays(startDate,dueDate){
      let due = new Date(dueDate)
      let now = new Date()
      let remain = due - now
      let days = Math.floor(remain / (24 * 3600 * 1000))
      return days
    },
   },
   watch:{
    contractData(val){
      this.contractStatus = false
      this.contracts = val
      val.forEach(contract => {
        if (contract.sign_status == true){
          let remainDays = this.computeRemainDays(contract.contract_start_date,contract.contract_due_date)
          if (remainDays > 0){
            this.contractStatus = true
          }
        }
      })
    },
    
  }
}
</script>

<style>

</style>
