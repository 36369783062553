<template>
  <b-sidebar
    id="timeline-sidebar"
    :visible="isTimelineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-timeline-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          新增时间线
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addTimeline()"
          @reset.prevent="resetForm"
        >
          <!-- 内容 -->
          <validation-provider
            #default="validationContext"
            name="内容"
            rules="required"
          >
            <b-form-group
              label="内容"
              label-for="content"
            >
            <b-form-textarea
              id="content"
              :state="getValidationState(validationContext)"
              v-model="timelineData.content"
              placeholder="请输入时间线内容..."
              rows="3"
            />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
              label="文件"
              label-for="file"
            >
            <b-form-file
              v-model="file"
              placeholder="请选择或拖拽文件至此..."
            />
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="submitDisable"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
            保存
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormFile,BSpinner
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { temp } from '@/@core/directives/animations'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    temp
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTimelineSidebarActive',
    event: 'update:is-timeline-sidebar-active',
  },
  props: {
    isTimelineSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      submitDisable: false,
      required,
      alphaNum,
      email,
      timelineData:{
        content:"",
      },
      file: null,
    }
  },
  methods:{
    addTimeline(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          const formData = new FormData()
          if (this.file) {
            formData.append('file', this.file)
          }
          formData.append('content',this.timelineData.content)
          formData.append('contact_id',this.$route.params.id)
          this.submitDisable = true
          this.$axios.post("crm/contacts/system/add_timeline",formData,{"Content-Type": "multipart/form-data"}).then(res=>{
            this.$emit('get-timeline')
            this.clearForm()
            this.submitDisable = false
          })
        }
      })
     
    },
    clearForm: function(){
      this.$refs.refFormObserver.reset()
      this.timelineData = {}
      this.file=null
    },
  },
  created(){
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
