<template>
  <b-sidebar
    id="upload-sidebar"
    :visible="isUploadSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-upload-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑合同
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="upload()"
          @reset.prevent="clearForm"
        >
        <validation-provider
            #default="validationContext"
            name="合同类型"
            rules="required"
          >
            <b-form-group
              label="合同类型"
              label-for="contract-type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="editingContractDataCopy.contract_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="contract-type"
              >
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <b-form-group
              label="合同"
              label-for="contract"
            >
            <b-form-file
              v-model="file"
              accept=".pdf"
              :placeholder="contractFilePlaceholder"
            />
            </b-form-group>

          <validation-provider
            #default="validationContext"
            name="生效日期"
            rules="required"
          >
            <b-form-group
              label="生效日期"
              label-for="contract-start-date"
            >
              <flat-pickr
                v-model="editingContractDataCopy.contract_start_date"
                class="form-control"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>

          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="截止日期"
            rules="required"
          >
          <b-form-group
              label="截止日期"
              label-for="contract-due-date"
            >
              <flat-pickr
                v-model="editingContractDataCopy.contract_due_date"
                class="form-control"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <b-form-group
              label="有效天数"
              label-for="contract-reamin-days"
            >
          <b-badge variant="light-success" v-if="contractRemainDays >= 0">
            {{ contractRemainDays }}
          </b-badge>

          <b-badge variant="light-danger" v-else>
            合同截止日期必须大于生效日期
          </b-badge>
          </b-form-group>


          <div class="d-flex mt-2">
            <b-button
              :disabled="isSaving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
            保存
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormFile,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { temp } from '@/@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'

import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    flatPickr,
    temp,
    BBadge
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUploadSidebarActive',
    event: 'update:is-upload-sidebar-active',
  },
  props: {
    editingContractData: {
      type: Object,
      required: true,
    },
    isUploadSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      file:null,
      isSaving: false,
      editingContractDataCopy: {
        contract_name:null,
      },
      typeOptions:[
        {value:'iceberg',label:'冰山合同模板'},
        {value:'customer',label:'客户合同模板'},
      ]
    }
  },
  computed:{
    contractFilePlaceholder(){
      if(this.editingContractDataCopy.contract_name){
        return this.editingContractDataCopy.contract_name
      }
      return '请选择或拖拽文件至此（仅支持pdf格式）...'
    },
    contractRemainDays(){
      if(this.editingContractDataCopy.contract_start_date && this.editingContractDataCopy.contract_due_date){
        return Math.floor((new Date(this.editingContractDataCopy.contract_due_date).getTime() - new Date(this.editingContractDataCopy.contract_start_date).getTime())/(24*3600*1000))
      }
      return 0
    }
  },
  methods:{
    upload(){
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if(new Date(this.editingContractDataCopy.contract_start_date).getTime() > new Date(this.editingContractDataCopy.contract_due_date).getTime()){
        this.$toast.error('合同截止日期必须大于生效日期')
        return
      }
      this.isSaving = true

      const formData = new FormData()
      formData.append('contract_id',this.editingContractDataCopy.contract_id)
      formData.append('contract_type',this.editingContractDataCopy.contract_type)
      formData.append('company_id',this.$route.params.id)
      formData.append('contract_start_date',this.editingContractDataCopy.contract_start_date)
      formData.append('contract_due_date',this.editingContractDataCopy.contract_due_date)
      if (this.file) formData.append('contract', this.file)
      
      this.$axios.post('/crm/companies/system/edit_contract',formData).then(res=>{
        this.isSaving = false
        if(res.data.status == "ok")
          this.clearForm()
          this.$emit('refresh-contracts')
          this.$emit('update:is-upload-sidebar-active', false)
        })
        }
      })

    },
    clearForm: function(){
      this.file=null
      this.contractType=null
      const today  = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      this.contractStartDate = year + '-' + month + '-' + day
      this.contractDueDate = year + 1 + '-' + month + '-' + day
      this.$refs.refFormObserver.reset()
    },
  },
  created(){
    const today  = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const day = today.getDate()
    this.contractStartDate = year + '-' + month + '-' + day
    this.contractDueDate = year + 1 + '-' + month + '-' + day
  },
  watch: {
    isUploadSidebarActive(val) {
      if (val) {
        this.editingContractDataCopy = JSON.parse(JSON.stringify(this.editingContractData))
      }
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
